import Cards from '../pages/Cards.svelte';
import WebView from '../pages/WebView.svelte';
import PageBuilder from '../pages/PageBuilder.svelte';
import { JanusInternationalizationManagerToken } from './i18n';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { writable, Writable } from 'svelte/store';
import { JanusSignonManagerToken } from './sso';
import BlankPage from 'pages/BlankPage.svelte';
import { CardsPageContentManagerToken } from 'components/cards/common/CardsPageContentManager';


const signonManager = getToken(ROOT, JanusSignonManagerToken)
const signonStatus = signonManager.getStatusComponent().store

const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const currentLanguage = i18nManager.getCurrentLanguage();

const contentManager = getToken(ROOT, CardsPageContentManagerToken);

/*
let languageUnsubscribe: Unsubscriber = null;
const enableLanguageUrlSwitch = (to, from, resolve, reject) => {
  const initialLang = to.params.lang;
  //console.log('SET CURRENT LANGUAGE', initialLang);
  i18nManager.setCurrentLanguage(initialLang);
  // Listen for changes in language and change route as needed
  // we need to subscribe on the next tick because the setCurrentLanguage value may not have passed through to the store yet
  setTimeout(() => languageUnsubscribe = i18nManager.getCurrentLanguageStore().subscribe((lang) => {
    console.log('LANGUAGE CHANGE', lang, to);
    if (lang !== initialLang) {
      f7.view.main.router.navigate({
        name: to.name,
        params: { ...to.params, lang },
        query: (Object.keys(to.query).length)? to.query : undefined
      }, {
        //clearPreviousHistory: true
      });
    }
  }), 0);
  resolve();
};
const disableLanguageUrlSwitch = (to, from, resolve, reject) => {
  if (languageUnsubscribe) {
    languageUnsubscribe();
    languageUnsubscribe = null;
  }
  resolve();
};
*/

const setLanguageBasedOnUrl = (to, from, resolve, reject) => {
  if (to?.params?.lang) {
    i18nManager.setCurrentLanguage(to.params.lang);
    setTimeout(() => resolve(), 0);
  }
}

export interface Route {
  query: { [key: string]: string };
  params: { [key: string]: string };
  url: string;
  path: string;
  // this is the route's source configuration (from the routes array below)
  route: {
    name: string;
    path: string;
  }
  name: string;
}

// This is set by YCApp.svelte
export const currentRoute: Writable<Route> = writable(null);

/*
const authGuard = (to, from, resolve, reject) => {
  const toListName = to.params.listName;
  const initialLang = to.params.lang;
  if( toListName === 'Profile') {
    return signonStatus.subscribe(signOn => {
      if (!signOn.loggedIn) {
        reject();
        f7.popup.open('.login-popup');
      } else {
        resolve()
      }
    })
  } else {
    resolve();
  }
}
*/

const modalOptions = {
  pushState: true,
  props: {
    isModal: true
  }
};

const popupOptions = {
  backdrop: true,
  backdropEl: '.popup-backdrop.cards-modal-popup-backdrop',
  closeByBackdropClick: true,
  closeOnEscape: true,
};

const modalEvents = {
  close: function(popup) {
    setTimeout(() => {
      const el = popup.el;
      popup.destroy();
      el.remove();
    }, 0);
  }
};

function buildPagesPathFromSlug(slug: string) {
  return `/pages/${currentLanguage}/${slug}`;
}
const homeRedirect = buildPagesPathFromSlug('home');
const fourOhFourRedirect = buildPagesPathFromSlug('404');
//const homeRedirect = `/home`;

let redirects = {};
if (CONFIG.league === 'fa' && CONFIG.tricode === 'bha') {
  redirects = {
    "/club-info/club-partners": buildPagesPathFromSlug("club-partners"),
    "/renew": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/cashless": buildPagesPathFromSlug("fans-matchday-at-the-amex"),
    "/seasonticket": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/albion-as-one": buildPagesPathFromSlug("albion-as-one"),
    "/contact": buildPagesPathFromSlug("contact-us"),
    "/membership": "https://tickets.brightonandhovealbion.com/",
    "/1901": buildPagesPathFromSlug("the-1901-club"),
    "/1901renewal": buildPagesPathFromSlug("1901-club-events"),
    "/privacy-policy": buildPagesPathFromSlug("privacy-policy"),
    "/hospitality/1901-club/existing-member": buildPagesPathFromSlug("1901-club-events"),
    "/seasontickets": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/st": "https://tickets.brightonandhovealbion.com/subscriptions",
    "/soccerschools": "https://bookings.albioninthecommunity.org.uk/soccerschools/courses/courses.htm?coursesSearch=search&typeId=78",
    "/resale": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/resale.html",
    "/ssopentimes": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/openingtimes.html",
    "/resubscribe": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/re-subscribe.html",
    "/1901tix": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/1901tickethelp.html",
    "/hospitality-guide-202122": "https://resources.brightonandhovealbion.com/bhafc/document/2021/10/27/4afc35c4-5ef0-4291-af11-8d6e20811502/MatchdayHospitalityGuide.pdf",
    "/pl-home-tickets": "https://tickets.brightonandhovealbion.com/en-gb/categories/home",
    "/matchdayroles": "https://www.brightonandhovealbion.com/club/club/jobs/matchday-roles",
    "/tunnel": "https://thetunnelclub.brightonandhovealbion.com/",
    "/club/stadiums/the-amex-stadium/fans-guide": buildPagesPathFromSlug("fans-matchday-at-the-amex"),
    "/help": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/askalbion.html",
    "/fab": buildPagesPathFromSlug("fans-fan-advisory-board"),
    "/christmas": "https://www.eventsatbhafc.co.uk/christmas-parties",
    "/myplanet": buildPagesPathFromSlug("environmental-sustainability"),
    "/teams/womens-team/players/45941/vicky-losada%3fselectcompetition=-1": fourOhFourRedirect,
    "/club/club/jobs": buildPagesPathFromSlug("career-opportunities"),
    "/club/stadiums/the-amex-stadium": "https://www.eventsatbhafc.co.uk/",
    "/club/matchday-hospitality": buildPagesPathFromSlug("matchday-hospitality"),
    "/club/matchday-hospitality/matchday-menus-and-guide": "https://downloads.ctfassets.net/rrpvignygaa1/3lST221zCnsHM3PhOvqKfr/74462d6ab11543e615db8c862c510fc3/65481_BHA-_Hospitality_Guide_2024_v3.pdf",
    "/hospitality/matchday-sponsorship/matchday-sponsor": buildPagesPathFromSlug("matchday-sponsors"),
  };
} else if (CONFIG.league === 'fa' && CONFIG.tricode === 'bir') {
  redirects = {
    "/auth/login": "home",
    "/auth/register": "home",
    "/auth/password": "home",
    "/news": "latest-news",
    "/news/mens": "mens-news",
    "/news/womens": "womens-news",
    "/news/u21s": "academy-news",
    "/news/academy": "academy-news",
    "/news/tickets": "ticket-news",
    "/news/matchday": "matchday-news",
    "/news/match-preview": "home",
    "/news/match-reaction": "home",
    "/news/match-report": "home",
    "/news/family-zone": "home",
    "/news/community": "",
    "/news/hospitality": "hospitality-news",
    "/news/events": "event-news",
    "/news/commercial": "commercial-news",
    "/news/club": "club-updates",
    "/news/club-update": "club-updates",
    "/news/bluestv": "bluestv-news",
    "/news/blues-store": "blues-store",
    "/news/archive": "home",
    "/bluestv": "latest-videos",
    "/bluestv/all": "latest-videos",
    "/bluestv/u21s": "latest-videos",
    "/bluestv/womens": "womens-news",
    "/bluestv/mens": "mens-news",
    "/bluestv/boxsets": "latest-videos",
    "/bluestv/post-match-interview": "latest-videos",
    "/bluestv/highlights": "latest-videos",
    "/bluestv/full-match": "latest-videos",
    "/bluestv/podcast": "latest-videos",
    "/bluestv/the-archive": "latest-videos",
    "/bluestv/feature": "latest-videos",
    "/bluestv/community": "latest-videos",
    "/bluestv/pre-season": "latest-videos",
    "/bluestv/academy": "latest-videos",
    "/bluestv/packages": "latest-videos",
    "/matches/mens-team/fixtures": "mens-fixtures",
    "/matches/mens-team/results": "mens-fixtures",
    "/matches/mens-team/tables": "mens-tables",
    "/matches/womens-team/fixtures": "womens-fixtures",
    "/matches/womens-team/results": "womens-fixtures",
    "/matches/womens-team/tables": "womens-tables",
    "/teams": "mens-team",
    "/teams/mens-team/squad-list": "mens-team",
    "/teams/mens-team/season-stats": "mens-fixtures",
    "/teams/womens-team/squad-list": "womens-team",
    "/tickets": "https://www.eticketing.co.uk/bcfc/",
    "/tickets/groundregulations": "ground-regulations",
    "/tickets/terms-conditions": "ticketing-terms-and-conditions",
    "/tickets/stadium-tours": "stadium-tours",
    "/tickets/mascot-celebration-packages": "mascot-packages",
    "/tickets/blues-ticketing-guide": "ticketing-guide",
    "/store": "https://clubstore.bcfc.com",
    "/store/blues-store-opening-times": "https://clubstore.bcfc.com",
    "/more": "home",
    "/more/fans": "supporters",
    "/more/fans/disabled-supporter-information": "disabled-supporter-information",
    "/more/fans/equality-diversity": "equality-and-diversity",
    "/more/fans/family-zone": "arthurs-area",
    "/more/fans/getting-to-the-ground": "getting-to-st-andrews-knighthead-park",
    "/more/fans/new-to-the-ground": "st-andrews-knighthead-park",
    "/more/fans/stadium-plan": "stadium-information",
    "/more/fans/supporter-charter": "supporter-charter",
    "/more/fans/where-to-drink-at-the-stadium": "where-to-eat-at-the-stadium",
    "/more/fans/where-to-eat-at-the-stadium": "where-to-eat-at-the-stadium",
    "/more/community": "foundation",
    "/more/community/about-us": "about-the-foundation",
    "/more/community/academy-schools": "academy-schools",
    "/more/community/community-hub": "community-hub",
    "/more/community/education": "education",
    "/more/community/football-for-all": "football-for-all",
    "/more/community/junior-blues": "junior-blues",
    "/more/community/premier-league-primary-stars": "primary-league-primary-stars",
    "/more/commercial": "commercial",
    "/more/commercial/advertising-sponsorship": "advertising",
    "/more/commercial/conferences-events": "commercial",
    "/more/commercial/download-brochure": "commercial",
    "/more/commercial/matchday-boxes": "hospitality-landing-page",
    "/more/commercial/matchday-hospitality-suites": "hospitality-landing-page",
    "/more/commercial/the-2023-24-blues-business-club": "home",
    "/more/club": "club",
    "/more/club/academy": "blues-academy",
    "/more/club/board-management": "board-management",
    "/more/club/charity-requests": "contact-the-club",
    "/more/club/contact": "contact-the-club",
    "/more/club/gender-pay-gap-report": "gender-pay-gap-report",
    "/more/club/history": "history",
    "/more/club/media-requests": "media-requests",
    "/more/club/safeguarding": "safeguarding",
    "/more/club/vacancies": "vacancies",
    "/more/hospitality": "hospitality-landing-page",
    "/privacy-policy": "privacy-policy",
    "/terms-and-conditions": "terms-of-use",
    "/financial-results": "financial-results"
  };  
} else if (CONFIG.league === 'fa' && CONFIG.tricode === 'lei') {
  redirects = {
    "/rss-podcast.xml": "https://feeds.buzzsprout.com/2370347.rss",
    "/shop": "https://shop.lcfc.com",
    "/tickets": "https://tickets.lcfc.com",
    "/watch": "foxes-hub",
    "/listen": "foxes-hub",
    "/news/3989109/introducing-the-new-lcfc-app-on-ios": "app",
    "/matches/fixtures": "matches-fixtures",
    "/teams/men": "first-team-men-squad",
    "/home": "home",
    "/account": "profile",
    "/foxeshub": "foxes-hub",
    "/foxeshub/live-video": "foxes-hub-live-2",
    "/matches/standings": "first-team-men-table",
    "/matches/results": "matches-results",
    "/club/careers/vacancies": "careers",
    "/women": "first-team-women-squad",
    "/tv": "foxes-hub",
    "/men": "first-team-men-squad",
    "/tv/live": "foxes-hub-live-2",
    "/foxeshub/live-audio": "foxes-hub",
    "/teams/women": "first-team-women-squad",
    "/king-power-stadium": "more-king-power-stadium",
    "/club/contact": "more-the-club-contact-us",
    "/hospitality": "matchday-hospitality",
    //"/Instagram": "-",
    "/king-power-stadium/parking-travel": "more-king-power-stadium-parking-and-travel",
    "/foxeshub/packages": "foxes-hub",
    "/teams/staff": "coaching-staff",
    "/packages": "foxes-hub",
    "/foxeshub/highlights": "foxes-hub-extended-highlights-2",
    "/academy": "teams-academy",
    "/teams/staff/men": "lcfc-men-staff",
    "/tours": "more-king-power-stadium-stadium-tours",
    "/community": "more-supporter-information-lcfc-help",
    "/spurscomp": "coaching-staff",
    "/king-power-stadium/stadium-map": "more-king-power-stadium-stadium-map",
    "/search": "search",
    "/u21s": "teams-academy",
    "/players/8979/Jamie-Vardy/profile": "player-detail/101668",
    "/foxeshub/territories": "foxes-hub",
    "/matches/matchday": "matchday-experiences",
    "/foxeshub/faqs": "foxes-hub",
    "/lcfc-training-ground": "",
    "/tv/3949407": "",
    "/academy/men/player-registration": "teams-academy",
    "/tv/live-video": "foxes-hub",
    "/radio": "foxes-hub",
    "/stadiumconsultation": "more-king-power-stadium-stadium-expansion-expansion",
    "/tv/latest": "foxes-hub",
    "/terms": "terms-of-use",
    "/foxeshub/on-demand": "foxes-hub",
    "/help": "more-supporter-information-lcfc-help",
    "/conferences-events": "more-events-conferences",
    "/community/football": "more-supporter-information-lcfc-help",
    "/club/senior-management": "more-the-club-senior-management",
    "/academy/women": "teams-academy",
    "/tv/3983335/prepare-like-we-have-all-season": "foxes-hub",
    "/index.html": "",
    "/tv/3897585": "foxes-hub",
    "/matches/stats": "",
    "/tv/live-audio": "foxes-hub",
    "/foxeshub/thank-you": "foxes-hub"
  };
}


const cardsPathPrefixes = [ '/pages', '/modals' ];
export function isCardsPathUrl(url: string) {
  if (!url) {
    return false;
  }
  url = url.toLowerCase();
  return cardsPathPrefixes.findIndex((prefix) => url.startsWith(prefix)) >= 0;
}

// WARNING: Redirects break prerendering for the website. Don't use redirects here unless absolutely necessary.
const routes = [
  {
    path: '/',
    //component: Home
    redirect: homeRedirect
  },
  {
    path: '/index.html',
    //component: Home,
    redirect: homeRedirect
  },
  {
    name: 'page-with-slug',
    path: '/pages/:lang/:listName/:path1',
    component: Cards,
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
    */
  },
  {
    name: 'pages',
    path: '/pages/:lang/:listName',
    component: Cards,
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch,
    */
  },
  {
    name: 'modal-with-slug',
    path: '/modals/:lang/:listName/:path1',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: [disableLanguageUrlSwitch]
    */
  },
  {
    name: 'modals',
    path: '/modals/:lang/:listName',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [ setLanguageBasedOnUrl ],
    /*
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
    */
  },
  {
    path: '/webview',
    component: WebView,
  },
  {
    path: '/builder',
    component: PageBuilder,
    beforeLeave: function ({resolve, reject}) {
      // "Relax," said the night man...
      if (typeof reject === 'function') {
        reject();
      }
    }
  },
  {
    path: '/login-redirect',
    component: BlankPage,
  },
  {
    path: '(.*)',
    redirect: function (route, resolve, reject) {
      console.log('catch all redirect', route);
      const userPath: string = route.path.toLowerCase();
      const redirectPath: string = redirects[userPath];
      let matches: RegExpMatchArray;
      const isInCrowd = (CONFIG.league === 'fa' && CONFIG.tricode === 'bir');
      const isPulseLive = (CONFIG.league === 'fa' && CONFIG.tricode === 'bha') || (CONFIG.league === 'fa' && CONFIG.tricode === 'lei');
      // test exact matches
      if (redirectPath) {
        if (redirectPath.startsWith('http://') || redirectPath.startsWith('https://')) {
          window.location.href = redirectPath;
          reject();
          return;
        } else if (redirectPath.startsWith('/')) {
          resolve(redirectPath);
          return;
        } else {
          resolve(buildPagesPathFromSlug(redirectPath));
          return;
        }
      // test InCrowd news article matches
      } else if (isInCrowd && (matches = userPath.match(/^\/news(?:\/[^\/]+)?\/([^\/]+)$/))) {
        const href = contentManager.getMediaTypeConfiguration('article', matches[1]).linkUrl.href;
        resolve(href);
        return;
      } else if (isInCrowd && (matches = userPath.match(/^\/bluestv(?:\/[^\/]+)?\/([^\/]+)$/))) {
        const href = contentManager.getMediaTypeConfiguration('video', matches[1]).linkUrl.href;
        resolve(href);
        return;
      // test Pulse news article matches
      } else if (isPulseLive && (matches = userPath.match(/^\/news\/\d+\/([^\/]+)/))) {
        const href = contentManager.getMediaTypeConfiguration('article', matches[1]).linkUrl.href;
        resolve(href);
        return;
      } else if (isPulseLive && (matches = userPath.match(/^\/tv\/\d+\/([^\/]+)/))) {
        resolve(contentManager.getMediaTypeConfiguration('video', matches[1]).linkUrl.href);
        return;
      } else if (isPulseLive && (matches = userPath.match(/^\/galleries\/\d+\/([^\/]+)/))) {
        resolve(contentManager.getMediaTypeConfiguration('gallery', matches[1]).linkUrl.href);
        return;
      }
      // otherwise go to 404
      resolve(fourOhFourRedirect);
    }
  },
];

export default routes;
